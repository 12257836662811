import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BaseHomePage from "../pages/General/BaseHomePage";
import {
  Alert,
  Box,
  Button,
  Grid,
  Typography,
  Container,
  CssBaseline,
  Stack,
  IconButton,
  Tooltip,
  Snackbar,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import PaperBaseComponent from "../components/general/PaperBaseComponent";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  FacebookIcon,
  FacebookShareButton,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  TwitterShareButton,
  TwitterIcon,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  EmailIcon,
  EmailShareButton,
} from "react-share";

export default function HomePage() {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const dealerURLString = `sweeven.live/${user.dealerURL}`;
  const dealerURL = `https://${dealerURLString}`;
  const messageToShare = "¡Descubre Sweeven en mi sitio web!";
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const verifyAmount = (amount) => {
    return amount ? amount : Number();
  };

  const imageStyle = () => {
    return {
      width: "60vw",
      height: "auto",
    };
  };

  const renderPromotioins = () => {
    return (
      <Grid
        container
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Grid item>
          <Box
            component="img"
            src="https://cdn.shopify.com/s/files/1/0555/8003/3192/files/Promo2.jpg"
            sx={imageStyle}
          />
        </Grid>
        <Grid item>
          <Box
            component="img"
            src="https://cdn.shopify.com/s/files/1/0555/8003/3192/files/Promo3.jpg"
            sx={imageStyle}
          />
        </Grid>
      </Grid>
    );
  };
  const renderView = () => {
    let view = (
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid mt={3} container spacing={1} sx={{ textAlign: "center" }}>
            <Grid item xs={12}>
              <PaperBaseComponent>
                <Typography variant="h3">{`Hola ${user.firstName}!`}</Typography>
              </PaperBaseComponent>
            </Grid>
            <Grid item xs={12}>
              <Typography mt={6} variant="h3">{`${t("range")}: ${
                user.currentRange ? t(user.currentRange) : t("star")
              }`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography mt={6} variant="h5">{`Volumen`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">{`Comisión: ${verifyAmount(
                user.commissionPoints
              )}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">{`Calificación: ${verifyAmount(
                user.calificationPoints
              )}`}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">{`Mi sitio:`}</Typography>
              <Link
                sx={{ fontSize: 30 }}
              >{`sweeven.live/${user.dealerURL}`}</Link>
              <Tooltip title={t("copyClipboard")}>
                <IconButton
                  onClick={async () => {
                    try {
                      await navigator.clipboard.writeText(dealerURL);
                      setOpen(true);
                    } catch (error) {
                      //TODO: HANDLE ERROR
                    }
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
              <Stack
                direction={"row"}
                spacing={1}
                sx={{
                  direction: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FacebookShareButton url={dealerURL} quote={""}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <FacebookMessengerShareButton url={dealerURL} appId="">
                  <FacebookMessengerIcon size={32} round />
                </FacebookMessengerShareButton>
                <TwitterShareButton url={dealerURL} title={dealerURLString}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <TelegramShareButton url={dealerURL} title={dealerURLString}>
                  <TelegramIcon size={32} round />
                </TelegramShareButton>

                <EmailShareButton url={dealerURL} subject={"title"} body="body">
                  <EmailIcon size={32} round />
                </EmailShareButton>

                <Box
                  sx={{
                    height: 32,
                    width: 32,
                    borderRadius: 16,
                    bgcolor: "#24D366",
                    display: "flex", // Agregamos display: 'flex' para centrar horizontalmente
                    alignItems: "center", // Centramos verticalmente
                    justifyContent: "center", // Centramos horizontalmente
                  }}
                >
                  <IconButton
                    href={`whatsapp://send?text=${messageToShare} ${dealerURL}`}
                    target="_blank"
                    data-action="share/whatsapp/share"
                  >
                    <WhatsAppIcon sx={{ color: "white" }} />
                  </IconButton>
                </Box>
                {/* <WhatsappShareButton
                  url={dealerURL}
                  title={"TITLE"}
                  separator=":: "
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton> */}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
    return view;
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <BaseHomePage>
      {renderView()}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Enlace copiado
        </Alert>
      </Snackbar>
    </BaseHomePage>
  );
}
