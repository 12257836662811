import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import BaseHomePage from "./General/BaseHomePage";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  CardHeader,
  Avatar,
  Box,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PaperBaseComponent from "../components/general/PaperBaseComponent";
import { getNetworkDealerService } from "../APIServices/UserAPIService";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TransferDealer from "../components/Genealogy/TrasnferDealer";
import { TreeChart } from "../components/TreeChart";
import { log } from "../helpers/Logger";

export default function GenealogyPage(props) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  // const dealerIdentifier = useSelector((state) => state.user.dealerIdentifier);
  const [dealers, setDealers] = useState(null);
  const [dealerSelected, setDealerSelected] = useState(null);
  const [viewData, setViewData] = useState({
    needData: true,
    loading: Boolean(),
    success: Number(),
  });

  const formatData = useCallback(
    (data) => {
      return data.map((item) => {
        return {
          name: item.name,
          attributes: { [t("dealerIdentifier")]: item.dealerIdentifier },
          child: formatData(item.childs),
        };
      });
    },
    [t]
  );

  const getGenealogy = useCallback(async () => {
    setViewData({
      needData: Boolean(),
      loading: true,
    });

    let response = {};
    try {
      response = await getNetworkDealerService(user.dealerIdentifier);
    } catch (error) {}

    setViewData({
      needData: Boolean(),
      loading: Boolean(),
    });

    if (response.code === 0) {
      let result = {
        name: `${user.firstName} ${user.lastName}`,
        attributes: { [t("dealerIdentifier")]: user.dealerIdentifier },
        children: formatData(response.data) || [],
      };

      setDealers(result);
    }
  }, [user, formatData, t]);

  const itemSelectedHandler = (item) => {
    setDealerSelected(item);
  };

  useEffect(() => {
    if (viewData.needData) {
      getGenealogy();
    }
  }, [viewData.needData, getGenealogy]);

  const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      "&.Mui-expanded": {
        fontWeight: theme.typography.fontWeightRegular,
      },
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        color: "var(--tree-view-color)",
      },
      [`& .${treeItemClasses.label}`]: {
        fontWeight: "inherit",
        color: "inherit",
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 0,
      [`& .${treeItemClasses.content}`]: {
        paddingLeft: theme.spacing(2),
      },
    },
  }));

  function StyledTreeItem(props) {
    const {
      bgColor,
      color,
      labelIcon: LabelIcon,
      labelInfo,
      labelText,
      ...other
    } = props;

    let iconColor = String();
    if (props.volume >= 100) {
      iconColor = "#00C700";
    } else if (props.volume > Number()) {
      iconColor = "#F98F04";
    }
    return (
      <StyledTreeItemRoot
        label={
          <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
            <Box component={LabelIcon} sx={{ mr: 1, color: iconColor }} />
            <Typography
              variant="body2"
              sx={{ fontWeight: "inherit", flexGrow: 1 }}
            >
              {labelText}
            </Typography>
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          </Box>
        }
        style={{
          "--tree-view-color": color,
          "--tree-view-bg-color": bgColor,
        }}
        {...other}
      />
    );
  }

  const renderItemTree = (items) => {
    return items.map((item) => {
      return (
        <StyledTreeItem
          key={item.dealerIdentifier}
          nodeId={String(item.dealerIdentifier)}
          labelText={`${item.dealerIdentifier} - ${item.name}`}
          labelIcon={item.calificationPoints > 60 ? CheckCircleIcon : ""}
          volume={item.calificationPoints}
          onClick={() => {
            itemSelectedHandler(item);
          }}
        >
          {renderItemTree(item.childs)}
        </StyledTreeItem>
      );
    });
  };

  const renderCard = (dealerSelected) => {
    if (dealerSelected) {
      return (
        <Card>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: "lightgray" }} aria-label="recipe">
                {String(dealerSelected.name)[0]}
              </Avatar>
            }
            // action={
            //   <ExpandMore
            //     expand={expanded}
            //     onClick={handleExpandClick}
            //     aria-expanded={expanded}
            //     aria-label="show more"
            //   >
            //     <ExpandMoreIcon />
            //   </ExpandMore>
            // }
            title={dealerSelected.name}
            subheader={dealerSelected.score}
          />
          <CardContent>
            {/* <Collapse in={expanded} timeout="auto" unmountOnExit> */}
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {dealerSelected.dealerIdentifier}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {`${dealerSelected.email}`}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {t("volume")}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {`${t("commissions")}: ${dealerSelected.commissionPoints}`}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {`${t("calification")}: ${dealerSelected.calificationPoints}`}
              </Typography>
            </CardContent>
            {/* </Collapse> */}
          </CardContent>
        </Card>
      );
    }
  };

  const renderTree = () => {
    let view = null;
    if (dealers) {
      if (dealers.length > 0) {
        view = (
          <Grid container>
            <Grid item xs={12} sm={3}>
              {renderCard(dealerSelected)}
            </Grid>
            <Grid item xs={12} sm={9}>
              <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{ flexGrow: 1, overflowY: "auto" }}
              >
                {renderItemTree(dealers)}
              </TreeView>
            </Grid>
          </Grid>
        );
      } else {
        view = <Typography>{t("emptyGenealogy")}</Typography>;
      }
    } else {
      view = <CircularProgress />;
    }
    return view;
  };

  const renderAssing = () => {};

  const renderView = () => {
    let view = null;
    if (dealers) {
      view = <TreeChart data={dealers} />;
    } else {
      view = <CircularProgress />;
    }
    return (
      <Box>
        {/* <TransferDealer /> */}
        {view}
      </Box>
    );
  };

  return (
    <BaseHomePage>
      <Paper>{renderView()}</Paper>
    </BaseHomePage>
  );
}
