import React from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Typography,
  Box,
  Button,
  Stack,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Wallet } from "@mercadopago/sdk-react";
import { initMercadoPago } from "@mercadopago/sdk-react";

initMercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY);

export default function SendLinkComponent(props) {
  const { t } = useTranslation();

  const renderView = () => {
    if (props.preference !== String()) {
      let message = `${t("enrollCompletEmail")} \n\n ${
        process.env.REACT_APP_HOST_HOME
      }/payment?&sponsor=${props.sponsorName}&preference=${
        props.preference
      }&dealerName=${props.dealerName}`;
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">{t("enrollCompletEmail")}</Typography>
          <CheckCircleIcon sx={{ fontSize: "6vw", color: "green" }} />
          <Typography
            variant="h6"
            sx={{ textAlign: "center" }}
          >{`Manda el link a tu asociado para que termine su inscripción`}</Typography>
          <Stack sx={{ m: 3 }} spacing={3}>
            <Button
              fullWidth
              href={`mailto:${props.email}?subject=${t(
                "paymentLink"
              )}&body=${encodeURIComponent(message)}`}
              variant="outlined"
              endIcon={<MailIcon />}
            >
              {t("email")}
            </Button>

            <Button
              fullWidth
              variant="outlined"
              href={`https://wa.me/${"525551908405"}?text=${encodeURIComponent(
                message
              )}`}
              endIcon={<WhatsAppIcon />}
            >
              {t("WhatsApp")}
            </Button>
          </Stack>
          <Box>
            <Typography
              variant="body1"
              sx={{ textAlign: "center" }}
            >{`Realizar el pago ahora`}</Typography>
            <Wallet initialization={{ preferenceId: props.preference }} />
          </Box>
        </Box>
        // <Grid
        //   container
        //   spacing={3}
        //   sx={{
        //     justifyContent: "center",
        //     direction: "column",
        //     alignItems: "center",
        //   }}
        // >
        //   <Grid item xs={12}>
        //     <Typography
        //       variant="h3"
        //       sx={{ textAlign: "center" }}
        //     >{`Registro completado`}</Typography>
        //     <CheckCircleIcon sx={{ fontSize: "6vw", color: "green" }} />
        //   </Grid>
        //   <Grid item xs={12}>
        //     <Typography
        //       variant="h6"
        //       sx={{ textAlign: "center" }}
        //     >{`Manda el link a tu asociado para que termine su inscripción`}</Typography>
        //   </Grid>
        //   <Grid item xs={6}>
        //     <Button
        //       href={`mailto:${props.email}?subject=${t(
        //         "paymentLink"
        //       )}&body=${""}`}
        //       variant="outlined"
        //       endIcon={<MailIcon />}
        //     >
        //       {t("email")}
        //     </Button>
        //   </Grid>
        //   <Grid item xs={6}>
        //     <Button
        //       variant="outlined"
        //       href={`https://google.com`}
        //       endIcon={<WhatsAppIcon />}
        //     >
        //       {t("WhatsApp")}
        //     </Button>
        //   </Grid>
        //   <Grid item xs={12}>
        //     <Typography
        //       variant="body1"
        //       sx={{ textAlign: "center" }}
        //     >{`¿Deseas realizar el pago? No hay proeblema, sola da `}</Typography>
        //   </Grid>
        //   <Grid item xs={12}>
        //     <Wallet initialization={{ preferenceId: preference }} />
        //   </Grid>
        // </Grid>
      );
    } else {
      return <CircularProgress />;
    }
  };

  return renderView();
}
