import React, { Suspense, useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import themeConfig from "./ColorsConfig";
import { AppRouter } from "./routers/AppRouter";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import "./App.css";
import ErrorBoundary from "./helpers/ErrorBoundary";
import { store } from "./store/store";
import PageLoadingComponent from "./components/general/PageLoadingComponent";
import CircularProgress from "./components/general/CirularProgress";

function App() {
  // const dispatch = useDispatch();
  // const appconfig = useSelector((state) => state.appconfig);
  // logEvent(analytics, "select_content", {
  //   content_type: "image",
  //   content_id: "P12453",
  //   items: [{ name: "Kittens", date: new Date().toISOString() }],
  // });
  // const [theme,setTheme] = useState(theme)

  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  // log(prefersDarkMode);
  let theme = themeConfig;

  // theme = {
  //   ...theme,
  //   palette: { ...theme.palette, mode: prefersDarkMode ? "dark" : "light" },
  // };

  // const theme = useMemo(
  //   () => {
  //     let theme = themeConfig
  //     return{
  //       theme = {
  //         ...theme,
  //         palette:
  //           {...theme.palette,
  //             mode: prefersDarkMode ? 'dark' : 'light'}

  //       }
  //     }
  //   },[prefersDarkMode],
  // );
  const [progress, setProgress] = useState(0);
  const radius = 60;
  useEffect(() => {
    // Simula un aumento gradual del progreso
    const interval = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress < 100 ? prevProgress + 5 : 100
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <ErrorBoundary>
      <Suspense fallback={<PageLoadingComponent />}>
        <Provider store={store}>
          <SnackbarProvider preventDuplicate maxSnack={6}>
            <ThemeProvider theme={theme}>
              <AppRouter />
            </ThemeProvider>
          </SnackbarProvider>
        </Provider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
