import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import BaseHomePage from "./General/BaseHomePage";
import {
  Grid,
  TextField,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PaperBaseComponent from "../components/general/PaperBaseComponent";
import { AddressesListComponent } from "../components/general/AddressesListComponent";
import { PhoneNumberComponent } from "../components/general/PhoneNumberComponent";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DealerDetailPage(props) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const [addressesView, setAddressesView] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const handleTextChange = (e) => {
    //TODO: ADD FUNCIONALITY OR REMOVE
    let value = e.target.value;
  };

  const handleAddressSelected = (identifer, address) => {
    //TODO: ADD FUNCIONALITY OR REMOVE
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderView = () => {
    return (
      <Box>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Item One"
                {...a11yProps(0)}
                icon={<ExpandMoreIcon />}
                iconPosition="start"
              />
              <Tab label="Item Two" {...a11yProps(1)} />
              <Tab label="Item Three" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            Item One
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            Item Two
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            Item Three
          </CustomTabPanel>
        </Box>
        <Box id="userForm" component="form" sx={{ mt: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant={"h3"}>{t("userData")}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={handleTextChange}
                value={user.firstName}
                margin="normal"
                required
                disabled={disabled}
                fullWidth
                name="firstName"
                label={t("firstName")}
                type="firstName"
                id="firstName"
                autoComplete="firstName"
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            {/*  */}
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={handleTextChange}
                disabled={disabled}
                value={user.lastName}
                margin="normal"
                required
                fullWidth
                name="lastName"
                label={t("lastName")}
                type="lastName"
                id="lastName"
                autoComplete="lastName"
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            {/*  */}
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={handleTextChange}
                value={user.email}
                disabled={true}
                margin="normal"
                required
                fullWidth
                id="email"
                type="email"
                autoComplete="email"
                label={t("email")}
                name="email"
              />
            </Grid>

            {/*  */}
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={handleTextChange}
                disabled={disabled}
                value={user.dealerURL}
                margin="normal"
                required
                fullWidth
                name="dealerURL"
                label={t("dealerURL")}
                type="text"
                id="dealerURL"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* <TextField
              onChange={(event) => {
                if (Number(event.target.value)) {
                  handleTextChange(event);
                }
              }}
              disabled={disabled}
              value={user.phone.number}
              margin="normal"
              required
              fullWidth
              name="phone"
              label={t("phone")}
              type="text"
              id="phone"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{"+52"}</InputAdornment>
                ),
              }}
            /> */}

              <PhoneNumberComponent
                required
                value={user.phone}
                disabled={disabled}
                name="phone"
                id="phone"
                label={t("phone")}
                onChange={(event) => {
                  if (Number(event.target.value)) {
                    handleTextChange(event);
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                onChange={handleTextChange}
                disabled={disabled}
                value={user.rfc}
                required
                fullWidth
                name="rfc"
                label={t("rfc")}
                type="text"
                id="rfc"
              />
            </Grid>
          </Grid>
        </Box>
        <Accordion
          onChange={async () => {
            if (addressesView == null) {
              setAddressesView(
                <AddressesListComponent
                  editMode={true}
                  title={t("")}
                  viewIdentifier="addresses"
                  onClick={handleAddressSelected}
                />
              );
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ fontSize: 42 }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5">{t("addresses")}</Typography>
          </AccordionSummary>
          <AccordionDetails>{addressesView}</AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  return (
    <BaseHomePage>
      <PaperBaseComponent>{renderView()}</PaperBaseComponent>
    </BaseHomePage>
  );
}
